import Immutable from 'immutable';
import { CALL_API } from 'redux-api-middleware';

import { CONTENT_API_ENDPOINT } from 'constants/endpoints';

const GET_PLACE_REQUEST = 'skylark/mapping/GET_PLACES_REQUEST';
const GET_PLACE_SUCCESS = 'skylark/mapping/GET_PLACES_SUCCESS';
const GET_PLACE_FAILURE = 'skylark/mapping/GET_PLACES_FAILURE';

const SET_HOVERED_POINT = 'skylark/mapping/SET_HOVERED_POINT';
const SET_SELECTED_POINT = 'skylark/mapping/SET_SELECTED_POINT';
const SET_CURRENT_PLACE = 'skylark/mapping/SET_CURRENT_PLACE';

// const LOCATION_CHANGED = '@@router/LOCATION_CHANGE';

const initialState = Immutable.fromJS({
  hoveredPoint: {},
  selectedPoint: {},
  currentPlace: {
    parent: null,
    object_type: null,
  },
  childPoints: [],
  items: {},
  loading: false,
  loaded: false,
});

export const requestPlace = accessCode => ({
  [CALL_API]: {
    endpoint: `${CONTENT_API_ENDPOINT}/places/${accessCode === 'world' ? '' : accessCode}`,
    method: 'GET',
    types: [GET_PLACE_REQUEST, GET_PLACE_SUCCESS, GET_PLACE_FAILURE],
    bailout: state => state.place.getIn(['items', accessCode || 'world']),
  },
});

export const setHoveredPoint = point => ({ type: SET_HOVERED_POINT, point });

export const clearHoveredPoint = () => ({ type: SET_HOVERED_POINT, point: Immutable.Map() });

export const setSelectedPoint = point => ({ type: SET_SELECTED_POINT, point });

export const clearSelectedPoint = () => ({ type: SET_SELECTED_POINT, point: Immutable.Map() });

export const setCurrentPlace = point => ({ type: SET_CURRENT_PLACE, point });

const setPlaceState = (state, payload) => {
  let children = null;
  const currentPlace = Immutable.fromJS(payload);
  const hotels = currentPlace.get('hotels');
  if (hotels) {
    children = hotels;
  } else {
    children = currentPlace.get('children');
  }
  return state
    .set('childPoints', children)
    .set('currentPlace', currentPlace)
    .set('loaded', true)
    .set('loading', false)
    .setIn(['items', currentPlace.get('access_code') || 'world'], currentPlace);
};

const setHoveredPointState = (state, point) => state.set('hoveredPoint', point);

const setSelectedPointState = (state, point) => state.set('selectedPoint', point);

const setRequestPlaceState = state => state.set('loaded', false).set('loading', true);

const setFailureState = state => state.set('loaded', false).set('loading', false);

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PLACE_REQUEST:
      return setRequestPlaceState(state);
    case GET_PLACE_SUCCESS:
      return setPlaceState(state, action.payload);
    case GET_PLACE_FAILURE:
      return setFailureState(state);
    case SET_HOVERED_POINT:
      return setHoveredPointState(state, action.point);
    case SET_SELECTED_POINT:
      return setSelectedPointState(state, action.point);
    case SET_CURRENT_PLACE:
      return setPlaceState(state, action.point);
    // case LOCATION_CHANGED:
    //   return setHoveredPointState(state, Immutable.Map());
    default:
      return state;
  }
};

export default reducer;
